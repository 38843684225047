var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index"},[_c('Tip',{on:{"childFn":_vm.parentFn}}),_c('div',{staticClass:"top",style:(_vm.topNode)},[_c('div',{staticClass:"record",on:{"click":_vm.record}},[_vm._v("领取记录")]),_c('div',{staticClass:"conts"},[_c('div',{staticClass:"conts-title"},[_c('div',{staticClass:"conts-title-crown",style:(_vm.contsTitleNode)}),_c('div',{staticClass:"conts-title-text"},[_c('span',{staticClass:"conts-title-text-icon left",style:(_vm.contsTitleLeft)}),_c('span',{staticClass:"conts-title-text-text"},[_vm._v("以下特权任选一")]),_c('span',{staticClass:"conts-title-text-icon right",style:(_vm.contsTitleRight)})])]),_c('ul',{staticClass:"conts-lists"},_vm._l((this.iconList),function(item,index){return _c('li',{key:index,staticClass:"conts-lists-item",class:{ choiced: index === _vm.current },on:{"click":function($event){return _vm.prerogative(item, index)}}},[_c('div',{staticClass:"conts-lists-item-icon",style:({
              backgroundImage: 'url(' + item.imgPath + ')',
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              borderRadius: '50%',
              transform: 'scale(.9)',
            })}),_c('div',{staticClass:"conts-lists-item-text"},[_c('p',[_vm._v(_vm._s(item.text))]),_c('p',[_vm._v(_vm._s(item.text === '美团外卖红包10元' ?'(有效期1个月)' : _vm.iconText))])])])}),0),_c('div',{staticClass:"conts-choice"},[_c('span',{staticClass:"conts-choice-icon"}),_c('span',{staticClass:"conts-choice-text"},[_vm._v(_vm._s(_vm.getText))])]),_c('div',{staticClass:"conts-infos"},[_c('p',{staticClass:"conts-infos-label"},[_vm._v("手机帐号")]),_c('p',{staticClass:"conts-infos-phone"},[_vm._v(_vm._s(this.$store.state.phone))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hide),expression:"hide"}],staticStyle:{"padding":"0 0.9rem"}},[_c('p',{staticClass:"conts-infos-label"},[_vm._v("QQ帐号")]),_c('div',{staticClass:"conts-infos-account"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.qqNum),expression:"qqNum"}],staticClass:"conts-infos-account-input",attrs:{"type":"tel","placeholder":"务必输入正确QQ号码","maxlength":"10"},domProps:{"value":(_vm.qqNum)},on:{"input":function($event){if($event.target.composing){ return; }_vm.qqNum=$event.target.value}}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hide),expression:"!hide"}],ref:"sureBtn",staticClass:"conts-infos-button conts-infos-button-unconfirmed",on:{"click":_vm.openVip}},[_c('i'),_c('span',[_vm._v("确认领取")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hide),expression:"hide"}],class:_vm.isActive === false
            ? 'active conts-infos-button': ' conts-infos-button-sure conts-infos-button',on:{"click":_vm.openVip}},[_c('i'),_c('span',[_vm._v("确认领取")])])]),_vm._m(0),_c('van-popup',{style:({
        width: '65%',
        height: '30%',
        borderRadius: '12px',
        padding: '48px 30px 20px',
      }),attrs:{"close-on-click-overlay":false},model:{value:(_vm.popShow),callback:function ($$v) {_vm.popShow=$$v},expression:"popShow"}},[_c('div',{staticClass:"popipContent"},[_c('div',{staticClass:"item title"},[_vm._v("温馨提醒")]),_c('div',{staticClass:"item"},[_vm._v(" 您领取的是 "),_c('span',{staticClass:"note"},[_vm._v(_vm._s(_vm.text))]),_vm._v("会员 ")]),_c('div',{staticClass:"item"},[_vm._v(" QQ号码："),_c('span',{staticClass:"note"},[_vm._v(_vm._s(_vm.qqNum))])]),_c('div',{staticClass:"item remark"},[_vm._v("注：一旦领取不可退改")]),_c('div',{staticClass:"item btn"},[_c('van-button',{attrs:{"size":"small","type":"danger"},on:{"click":_vm.cancel}},[_vm._v("返回修改")]),_c('van-button',{attrs:{"size":"small","type":"info"},on:{"click":_vm.receiveVip}},[_vm._v("确认领取")])],1)])])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rules"},[_c('div',{staticClass:"rules-lists"},[_c('div',{staticClass:"rules-title"},[_vm._v("· 注意事项 ·")]),_c('div',{staticClass:"rules-text"},[_c('p',{staticClass:"rules-txt"},[_vm._v(" 1、每个手机号或者充值账号只可充值一次。 ")]),_c('p',{staticClass:"rules-txt"},[_vm._v(" 2、每次选择会员时务必填写购买时的手机号码; 腾讯视频、QQ音乐、QQ会员要求填写正确的QQ号码，该三项权益要求通过QQ账号登录。 ")]),_c('p',{staticClass:"rules-txt"},[_vm._v(" 3、本商品只能在手机、电脑、ipad上使用，均非TV版。 ")]),_c('p',{staticClass:"rules-txt"},[_vm._v(" 4、若24小时后还未激活，请及时联系客服。 ")]),_c('br')])])])}]

export { render, staticRenderFns }